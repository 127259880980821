import Navbar from '../components/Navbar/Navbar';
import Header from '../components/Header/Header';
import About from '../components/About/About';
import Service from '../components/NosServices/Service';
import PhotoGallery from '../components/Gallery/HomeGallery';
import Contact from '../components/Contact/Contact';
import React from 'react';
import AvisHome from '../components/Avis/AvisHome';

const App = () => {
    return (
        <div>
            <Navbar />
            <Header></Header>
            <About></About>
            <Service></Service>
            <PhotoGallery></PhotoGallery>
            <AvisHome></AvisHome>
            <Contact></Contact>
        </div>
    );
};

export default App;
