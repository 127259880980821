import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import AvisComponent from './AvisComponent';
import { Center, chakra, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdImageSearch } from 'react-icons/md';
import { Link as RouteLink } from 'react-router-dom';

const AvisHome = () => {
    const { data: avis } = useQuery(['avis'], () =>
        axios(process.env.REACT_APP_URL_BACK + '/getAvisLimitFlashEven'),
    );

    return (
        <>
            <div id="avis">
                <chakra.h1
                    fontFamily="Niconne"
                    color="#8B6464"
                    fontSize="5xl"
                    fontWeight="thin"
                    mb={10}
                    textAlign="center"
                >
                    Nos Avis
                </chakra.h1>

                {avis ? (
                    <>
                        <Center>
                            <Text
                                fontSize="lg"
                                color="gray.700"
                                width={'60%'}
                                textAlign="center"
                            >
                                Voici quelques exemples de nos avis. Afin de
                                voir l'intégralité de nos réalisations cliquez
                                sur le bouton en dessous de ces avis
                            </Text>
                        </Center>
                        <AvisComponent avis={avis.data} />
                    </>
                ) : (
                    <Center>
                        <Text
                            fontSize="lg"
                            color="gray.700"
                            width={'60%'}
                            textAlign="center"
                        >
                            Aucun avis pour le moment
                        </Text>
                        <br />
                    </Center>
                )}
            </div>
            <Center>
                <Link as={RouteLink} to="/avis">
                    <Button
                        leftIcon={<MdImageSearch />}
                        px={8}
                        mb={10}
                        bg={'#8B6464'}
                        color={'white'}
                        rounded={'md'}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}
                    >
                        Voir tous nos avis
                    </Button>
                </Link>
            </Center>
        </>
    );
};

export default AvisHome;
