import {
    Box,
    Container,
    chakra,
    Text,
    SimpleGrid,
    Flex,
} from '@chakra-ui/react';
import { BiCake } from 'react-icons/bi';
import { GiPartyFlags } from 'react-icons/gi';
import { IoShirtOutline } from 'react-icons/io5';
import { FaRegLightbulb } from 'react-icons/fa';
import { BsPatchQuestion } from 'react-icons/bs';
import { CgRing } from 'react-icons/cg';

const Service = () => {
    const features = [
        {
            heading: 'Mariage',
            content:
                'Nous organisons votre mariage à votre goût afin que vous puissiez profiter de votre grand jour',
            icon: <CgRing size={'2rem'} />,
        },
        {
            heading: 'Anniversaire, Baptême',
            content:
                'Nous organisons votre anniversaire ou baptême selon vos envies et vos besoins',
            icon: <BiCake size={'2rem'} />,
        },
        {
            heading: 'Soirée à thème, Séminaire',
            content:
                'Nous organisons votre soirée à thème ou votre séminaire en fonction de vos besoins',
            icon: <GiPartyFlags size={'2rem'} />,
        },
        {
            heading: 'Vente de tissu',
            content:
                'Nous proposons la vente de tissus que nous avons soigneusement créé.',
            icon: <IoShirtOutline size={'2rem'} />,
        },
        {
            heading: 'Location de matériel et divers',
            content:
                'Nous proposons la location de différents matériels de décoration.',
            icon: <FaRegLightbulb size={'2rem'} />,
        },
        {
            heading: 'Autre proposition sur demande',
            content:
                "Autre idée n'hésitez pas à proposer en passant par l'espace nous contacter.",
            icon: <BsPatchQuestion size={'2rem'} />,
        },
    ];

    return (
        <Container id="services" maxW="6xl" p={{ base: 5, md: 10 }} zIndex={-1}>
            <chakra.h3
                fontFamily="Niconne"
                color="#8B6464"
                fontSize="5xl"
                fontWeight="thin"
                mb={20}
                textAlign="center"
            >
                Nos différents services
            </chakra.h3>
            <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3 }}
                placeItems="center"
                spacing={10}
                mb={4}
            >
                {features.map((feature, index) => (
                    <Box
                        key={index}
                        bg={'#D6CDA4'}
                        p={6}
                        rounded="lg"
                        textAlign="center"
                        pos="relative"
                    >
                        <Flex
                            p={2}
                            w="max-content"
                            color="white"
                            bgGradient="linear(to-br, #228be6, #15aabf)"
                            rounded="md"
                            marginInline="auto"
                            pos="absolute"
                            left={0}
                            right={0}
                            top="-1.5rem"
                            boxShadow="lg"
                        >
                            {feature.icon}
                        </Flex>
                        <chakra.h3 fontWeight="semibold" fontSize="2xl" mt={6}>
                            {feature.heading}
                        </chakra.h3>
                        <Text fontSize="md" mt={4} color="gray.700">
                            {feature.content}
                        </Text>
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    );
};

export default Service;
