import Navbar from '../components/Navbar/Navbar';
import ImageGallery from '../components/Gallery/ImageGallery';

const Gallery = () => {
    return (
        <>
            <Navbar />
            <ImageGallery />
        </>
    );
};

export default Gallery;
