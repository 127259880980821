import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Gallery from './pages/Gallery';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AllAvis from './components/Avis/AllAvis';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
    <BrowserRouter>
        <ChakraProvider>
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route path="/realisations" element={<Gallery />} />
                    <Route path="/avis" element={<AllAvis />} />
                </Routes>
            </QueryClientProvider>
        </ChakraProvider>
    </BrowserRouter>,
);
