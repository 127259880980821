import {
    Box,
    SimpleGrid,
    Flex,
    Image,
    chakra,
    Text,
    Center,
    Button,
    Link,
} from '@chakra-ui/react';
import { ImageViewer } from 'react-image-viewer-dv';
import BabyShower from '../PhotoSlider/images/BabyShower/275380275_5110809972308513_4923338688593323781_n.jpg';
import Disney from '../PhotoSlider/images/Disney/26221277_1670112616378283_6851419905987632563_o.jpg';
import Orchidee from '../PhotoSlider/images/Orchidee/10306552_743880745668146_3836363509248501456_n.jpg';
import { MdImageSearch } from 'react-icons/md';
import { Link as RouteLink } from 'react-router-dom';
import React from 'react';

const PhotoGallery = () => {
    const photos = [{ src: BabyShower }, { src: Disney }, { src: Orchidee }];

    return (
        <div id="gallery">
            <chakra.h1
                fontFamily="Niconne"
                color="#8B6464"
                fontSize="5xl"
                fontWeight="thin"
                mb={10}
                textAlign="center"
            >
                Nos quelques réalisations
            </chakra.h1>
            <Center>
                <Text
                    fontSize="lg"
                    color="gray.700"
                    width={'60%'}
                    textAlign="center"
                >
                    Voici quelques exemples de nos réalisations. Afin de voir
                    l'intégralité de nos réalisations cliquez sur le bouton en
                    dessous des images
                </Text>
            </Center>
            <SimpleGrid
                columns={{ base: 1, sm: 2, md: 3 }}
                placeItems="center"
                spacing={2}
                mb={4}
            >
                {photos.map((photo) => (
                    <Box
                        key={photo.src}
                        p={6}
                        rounded="lg"
                        textAlign="center"
                        pos="relative"
                    >
                        <Flex
                            p={2}
                            w="fit-content"
                            color="white"
                            rounded="md"
                            marginInline="auto"
                        >
                            <ImageViewer>
                                <Image width={'fit-content'} src={photo.src} />
                            </ImageViewer>
                        </Flex>
                    </Box>
                ))}
            </SimpleGrid>
            <Center>
                <Link as={RouteLink} to="/realisations">
                    <Button
                        leftIcon={<MdImageSearch />}
                        px={8}
                        mb={10}
                        bg={'#8B6464'}
                        color={'white'}
                        rounded={'md'}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }}
                    >
                        Voir toutes nos réalisations
                    </Button>
                </Link>
            </Center>
        </div>
    );
};

export default PhotoGallery;
