import { Box, Text, Flex } from '@chakra-ui/react';
import React from 'react';

const AvisComponent = ({ avis }) => (
    <Flex flexWrap="wrap">
        {avis.map((item) => (
            <Box key={item.id} p="4" w={{ base: '100%', md: '30%' }}>
                <Box
                    h="full"
                    color="white"
                    p="8"
                    rounded="lg"
                    shadow="lg"
                    borderColor="gray.600"
                    bg={'#D6CDA4'}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 975.036 975.036"
                        style={{
                            display: 'block',
                            width: '1.25rem',
                            height: '1.25rem',
                            color: 'gray.700',
                            marginBottom: '1rem',
                        }}
                    >
                        <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <Text
                        fontSize="md"
                        mb="6"
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                        color="black"
                    >
                        {item.content}
                    </Text>
                    <Box as="a" display="inline-flex" alignItems="center">
                        <Box flex="1" display="flex" flexDir="column">
                            <Text
                                fontSize="md"
                                color="gray.700"
                                fontWeight="medium"
                            >
                                {item.name}
                            </Text>
                            <Text fontSize="sm" color="gray.700">
                                {new Date(item.dateAvis).toLocaleDateString(
                                    'fr-FR',
                                )}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        ))}
    </Flex>
);

export default AvisComponent;
