import Gallery from 'react-photo-gallery';
import React, { useState, useCallback } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import style from './ImageGallery.css';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Select } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';

const ImageGallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [listPhotos, setListPhotos] = useState();
    const { register, watch } = useForm();
    const watchTheme = watch('theme');

    const openModal = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeModal = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const { data: dataThemes } = useQuery(['themes'], () =>
        axios(process.env.REACT_APP_URL_BACK + '/getAllThemesFlashEven'),
    );

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL_BACK}/getAllFilesFlashEven`)
            .then((res) => {
                setListPhotos(res.data);
            });
    }, []);

    useEffect(() => {
        if (
            dataThemes &&
            dataThemes.data &&
            dataThemes.data.includes(watchTheme)
        ) {
            axios
                .get(
                    `${process.env.REACT_APP_URL_BACK}/getFilesByThemeFlashEven/${watchTheme}`,
                )
                .then((res) => {
                    setListPhotos(res.data);
                });
        } else {
            axios
                .get(`${process.env.REACT_APP_URL_BACK}/getAllFilesFlashEven`)
                .then((res) => {
                    setListPhotos(res.data);
                });
        }
    }, [watchTheme, dataThemes]);

    return (
        <div className={style} style={{ paddingTop: '80px' }}>
            <Center>
                <FormProvider>
                    <Select
                        {...register('theme')}
                        placeholder="Sélectionnez un thème"
                        borderRadius={'20'}
                        boxShadow="2xl"
                        bgColor="white"
                        py={6}
                        w={'50%'}
                    >
                        {dataThemes &&
                            dataThemes.data.map((theme) => (
                                <option key={theme} value={theme}>
                                    {theme}
                                </option>
                            ))}
                    </Select>
                </FormProvider>
            </Center>
            {listPhotos && (
                <>
                    <Gallery photos={listPhotos} onClick={openModal} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeModal}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={listPhotos.map((x) => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </>
            )}
        </div>
    );
};

export default ImageGallery;
