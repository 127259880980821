import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
    Grid,
    Input,
    Select,
    Box,
    Text,
    Center,
    Button,
    Textarea,
    List,
    ListItem,
    ListIcon,
    Alert,
    AlertIcon,
    SlideFade,
    RadioGroup,
    Radio,
    VStack,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Stack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDisclosure } from '@chakra-ui/react';
import { AlertTitle } from '@chakra-ui/react';
import { AlertDescription } from '@chakra-ui/react';
import { CloseButton } from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';

const Contact = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const form = useRef();
    const typePrestation = watch('typePrestation');
    const { isOpen: isVisibleAlert } = useDisclosure({
        defaultIsOpen: true,
    });

    const [showAlert, setShowAlert] = useState(true);

    const typeDecoration = () => {
        return (
            <>
                <Input
                    type="string"
                    {...register('budget', {
                        required: 'Le budget est obligatoire',
                    })}
                    placeholder="Budget"
                    borderRadius={'20'}
                    boxShadow="2xl"
                    bgColor="white"
                    py={6}
                />
                <Input
                    type="string"
                    {...register('nbrConvives', {
                        required: 'Le nombre de convives est obligatoire',
                    })}
                    placeholder="Nombre de convives"
                    borderRadius={'20'}
                    boxShadow="2xl"
                    bgColor="white"
                    py={6}
                />
            </>
        );
    };

    const typeCommandeTissu = () => {
        return (
            <>
                <Input
                    type="string"
                    {...register('longueur', {
                        required: 'La longueur du tissu est obligatoire',
                    })}
                    placeholder="Longueur"
                    borderRadius={'20'}
                    boxShadow="2xl"
                    bgColor="white"
                    py={6}
                />
                <Input
                    type="string"
                    {...register('couleur', {
                        required: 'La couleur est obligatoire',
                    })}
                    placeholder="Couleur"
                    borderRadius={'20'}
                    boxShadow="2xl"
                    bgColor="white"
                    py={6}
                />
            </>
        );
    };

    const typeCommandeMateriel = () => {
        return (
            <Stack direction={'column'} spacing={4}>
                <Text fontSize={['20px', '20px', '20px']}>
                    Quelle type de matériel ?
                </Text>
                <RadioGroup
                    {...register('typeMateriel', {
                        required: 'Le type de matériel est obligatoire',
                    })}
                >
                    <VStack align="start" spacing={2}>
                        <Radio value="vase">Vase pour centre de table</Radio>
                        <Radio value="nappes">Nappes rondes / rectangles</Radio>
                        <Radio value="housseChaise">Housse de chaise</Radio>
                        <Radio value="noeudsChaise">Noeuds de chaise</Radio>
                        <Radio value="autre">Autre proposition</Radio>
                    </VStack>
                </RadioGroup>
            </Stack>
        );
    };

    const mutation = useMutation((sendMailDevis) =>
        axios.post(
            `${process.env.REACT_APP_URL_BACK}/sendMailDevis`,
            sendMailDevis,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        ),
    );

    const onSubmit = (data) => {
        setShowAlert(true);
        mutation.mutate(data);
    };

    useEffect(() => {
        if (isVisibleAlert && mutation.isError) {
            setShowAlert(true);
        }
    }, [isVisibleAlert, mutation.isError]);

    return (
        <>
            <ReactVisibilitySensor
                id="devis"
                offset={{ bottom: 200 }}
                partialVisibility={true}
            >
                {({ isVisible }) => (
                    <SlideFade in={isVisible} offsetY="80px">
                        {showAlert && mutation.isSuccess && (
                            <Center>
                                <Alert
                                    status="success"
                                    maxW="4xl"
                                    position="fixed"
                                    top="0"
                                    left="50%"
                                    transform="translateX(-50%)"
                                    mt={5}
                                    zIndex={1}
                                >
                                    <AlertIcon />
                                    <Box flex={1}>
                                        <AlertTitle>Succès</AlertTitle>
                                        <AlertDescription>
                                            {mutation.data &&
                                                mutation.data.data}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        alignSelf="flex-start"
                                        position="relative"
                                        right="-1"
                                        top="-1"
                                        onClick={() => setShowAlert(false)}
                                    />
                                </Alert>
                            </Center>
                        )}
                        {showAlert && mutation.error && (
                            <Center>
                                <Alert
                                    status="error"
                                    maxW="4xl"
                                    position="fixed"
                                    top="0"
                                    left="50%"
                                    transform="translateX(-50%)"
                                    mt={5}
                                    zIndex={1}
                                >
                                    <AlertIcon />
                                    <Box flex={1}>
                                        <AlertTitle>Erreur</AlertTitle>
                                        <AlertDescription>
                                            Une erreur interne est survenue,
                                            veuillez réessayer ultérieurement ou
                                            nous contacter par mail :{' '}
                                            {process.env.REACT_APP_MAIL_CONTACT}
                                        </AlertDescription>
                                    </Box>
                                    <CloseButton
                                        alignSelf="flex-start"
                                        position="relative"
                                        right="-1"
                                        top="-1"
                                        onClick={() => setShowAlert(false)}
                                    />
                                </Alert>
                            </Center>
                        )}
                        <form
                            id="contact"
                            ref={form}
                            className="contactForm"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Box
                                borderRadius={'20'}
                                width={['90%', '85%', '50%']}
                                mx={'auto'}
                                bgColor={'#D6CDA4'}
                                py="30px"
                                px={['20px', '30px', '30px']}
                            >
                                <Center>
                                    <Text
                                        fontSize={['35px', '40px', '50px']}
                                        fontFamily="Niconne"
                                        pb="30px"
                                    >
                                        Obtenir un devis
                                    </Text>
                                </Center>
                                <Grid
                                    flexWrap={'wrap'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gridTemplateColumns={[
                                        'none',
                                        'repeat(auto-fill, 40%)',
                                    ]}
                                    gridColumnGap={'50px'}
                                    gridRowGap={'30px'}
                                    autoColumns
                                >
                                    <Input
                                        width={'100%'}
                                        type="text"
                                        {...register('firstLastName', {
                                            required:
                                                'Le Prénom/Nom est obligatoire',
                                            maxLength: 20,
                                        })}
                                        placeholder="Nom et Prénom"
                                        borderRadius={'20'}
                                        boxShadow="2xl"
                                        bgColor="white"
                                        py={6}
                                    />
                                    <Input
                                        type="email"
                                        {...register('email', {
                                            required: "L'email est obligatoire",
                                        })}
                                        placeholder="Email"
                                        borderRadius={'20'}
                                        boxShadow="2xl"
                                        bgColor="white"
                                        py={6}
                                    />
                                    <Input
                                        type="tel"
                                        {...register('phone', {
                                            required:
                                                'Le numéro de téléphone est obligatoire',
                                            pattern: {
                                                value: /^((\+)33|0)[1-9](\d{2}){4}$/g,
                                                message:
                                                    "Le numéro de téléphone n'est pas valide",
                                            },
                                            maxLength: {
                                                value: 10,
                                                message:
                                                    'Le numéro de téléphone est trop grand',
                                            },
                                        })}
                                        placeholder="Téléphone"
                                        borderRadius={'20'}
                                        boxShadow="2xl"
                                        bgColor="white"
                                        py={6}
                                    />
                                    <Input
                                        type="date"
                                        {...register('date', {
                                            required:
                                                "La date de l'évènement est obligatoire",
                                            validate: {
                                                greather: (date) => {
                                                    const dateNow = new Date()
                                                        .toISOString('fr')
                                                        .split('T')[0];
                                                    if (dateNow >= date) {
                                                        return "La date de l'évènement doit être supérieur à la date du jour";
                                                    }
                                                },
                                            },
                                        })}
                                        placeholder="JJ/MM/AAAA"
                                        borderRadius={'20'}
                                        boxShadow="2xl"
                                        bgColor="white"
                                        py={6}
                                    />
                                    <Select
                                        placeholder="Quel type de prestation"
                                        {...register('typePrestation', {
                                            required:
                                                'Le type de prestation est obligatoire',
                                        })}
                                        borderRadius={'20'}
                                        boxShadow="2xl"
                                        bgColor="white"
                                        py={6}
                                    >
                                        <option value="decoration">
                                            Décoration de salle
                                        </option>
                                        <option value="commandeTissu">
                                            Vente de tissu
                                        </option>
                                        <option value="commandeMateriel">
                                            Location de matériel
                                        </option>
                                        <option value="autres">Autres</option>
                                    </Select>
                                    {typePrestation === 'decoration'
                                        ? typeDecoration()
                                        : null}
                                    {typePrestation === 'commandeTissu'
                                        ? typeCommandeTissu()
                                        : null}
                                    {typePrestation === 'commandeMateriel'
                                        ? typeCommandeMateriel()
                                        : null}
                                </Grid>
                                {typePrestation ? (
                                    <Center py={10}>
                                        <Textarea
                                            {...register('informationSup', {
                                                required:
                                                    typePrestation === 'autres'
                                                        ? 'Le champ que recherchez-vous est obligatoire'
                                                        : false,
                                            })}
                                            placeholder={
                                                typePrestation === 'autres'
                                                    ? 'Que recherchez-vous ?'
                                                    : 'Informations supplémentaires'
                                            }
                                            borderRadius={'20'}
                                            boxShadow="2xl"
                                            bgColor="white"
                                            width={'90%'}
                                            rows="4"
                                        />
                                    </Center>
                                ) : null}
                                <List>
                                    <ListItem color={'red.500'}>
                                        {errors.firstLastName?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.firstLastName?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.email?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.email?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.phone?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.phone?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.date?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.date?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.typePrestation?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.typePrestation?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.budget?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.budget?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.nbrConvives?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.nbrConvives?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.longueur?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.longueur?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.couleur?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.couleur?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <ListItem color={'red.500'}>
                                        {errors.informationSup?.message ? (
                                            <>
                                                <ListIcon
                                                    as={WarningIcon}
                                                    color={'red.500'}
                                                />
                                                {errors.informationSup?.message}
                                            </>
                                        ) : null}
                                    </ListItem>
                                    <br />
                                </List>
                                <Center>
                                    <Button
                                        width={['40%', '25%']}
                                        colorScheme={'teal'}
                                        variant={'solid'}
                                        type="submit"
                                        value="Valider"
                                        borderRadius={'15'}
                                        isLoading={mutation.isLoading}
                                    >
                                        Envoyer
                                    </Button>
                                </Center>
                            </Box>
                        </form>
                    </SlideFade>
                )}
            </ReactVisibilitySensor>
        </>
    );
};

export default Contact;
