import boule from './image/boule.png';
import roue from './image/roue.png';
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';
import { TimeIcon, CheckIcon } from '@chakra-ui/icons';

const About = () => {
    const Feature = ({ text, icon, iconBg }) => {
        return (
            <Stack direction={'row'} align={'center'}>
                <Flex
                    w={8}
                    h={8}
                    align={'center'}
                    justify={'center'}
                    rounded={'full'}
                    bg={iconBg}
                >
                    {icon}
                </Flex>
                <Text fontWeight={600}>{text}</Text>
            </Stack>
        );
    };

    return (
        <>
            <Container maxW={'7xl'} py={12} id="about">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={50}>
                    <Stack spacing={4}>
                        <Heading
                            fontFamily="Niconne"
                            color="#8B6464"
                            fontWeight="thin"
                        >
                            Qui sommes nous ?
                        </Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            La société a fait ses débuts en février 2010, notre
                            métier; mettre en lumière tous types d'événements
                            qui puissent exister, en les décorant de la
                            meilleure des manières possibles (mariage, baptême,
                            anniversaire, soirée à thème, etc...). Nous sommes
                            dans le milieu depuis plus de 15 ans, un peu plus de
                            300 décorations de salles basées sur de nombreuses
                            formations décoratives et structures ballons... nous
                            sommes les personnes qu'il vous faut pour embellir
                            votre salle! Mon équipe et moi sommes des personnes
                            de confiance, tant dans l'efficacité de notre
                            travail que dans le renseignement et la prise en
                            charge de nos clients pour la décoration de leurs
                            évènements ...
                        </Text>
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue(
                                        'gray.100',
                                        'gray.700',
                                    )}
                                />
                            }
                        >
                            <Feature
                                icon={
                                    <Icon
                                        as={TimeIcon}
                                        color={'green.500'}
                                        w={5}
                                        h={5}
                                    />
                                }
                                iconBg={useColorModeValue(
                                    'green.100',
                                    'green.900',
                                )}
                                text={'15 ans d’expérience'}
                            />
                            <Feature
                                icon={
                                    <Icon
                                        as={CheckIcon}
                                        color={'green.500'}
                                        w={5}
                                        h={5}
                                    />
                                }
                                iconBg={useColorModeValue(
                                    'green.100',
                                    'green.900',
                                )}
                                text={'+ 300 décorations de salles'}
                            />
                        </Stack>
                    </Stack>
                    <Flex>
                        <Image
                            rounded={'md'}
                            alt={'Boule de décoration'}
                            src={boule}
                            objectFit={'cover'}
                        />
                    </Flex>
                </SimpleGrid>
            </Container>
            <Container maxW={'7xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Flex>
                        <Image
                            rounded={'md'}
                            alt={'Roue de décoration'}
                            src={roue}
                            objectFit={'cover'}
                        />
                    </Flex>
                    <Stack spacing={4}>
                        <Heading
                            fontFamily="Niconne"
                            color="#8B6464"
                            fontWeight="thin"
                        >
                            Votre satisfaction, notre devoir
                        </Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            Nous détenons plusieurs cordes à notre arc:
                            décorateur, vente ou location de matériel décoratif,
                            formation pour la décoration d'un événement à venir,
                            et nous pouvons même vous proposer un dj qui
                            travaille en collaboration avec nous. Les
                            déplacements et les DEVIS sont GRATUITS sur toute
                            l’île de France, idem pour les régions de l'Eure et
                            de l'Eure et Loire, ainsi que dans toutes les
                            régions du Nord de la France. Nous étudions chacun
                            de vos projets et sommes à l'écoute de vos remarques
                            afin de vous proposer une expérience unique. Nous
                            mettons un point d'honneur à faire de chacune de nos
                            prestations un moment d'exception.
                        </Text>
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderColor={useColorModeValue(
                                        'gray.100',
                                        'gray.700',
                                    )}
                                />
                            }
                        >
                            <Feature
                                icon={
                                    <Icon
                                        as={CheckIcon}
                                        color={'green.500'}
                                        w={5}
                                        h={5}
                                    />
                                }
                                iconBg={useColorModeValue(
                                    'green.100',
                                    'green.900',
                                )}
                                text={
                                    'Décorateur, vente, location de matériel décoratif, Dj '
                                }
                            />
                            <Feature
                                icon={
                                    <Icon
                                        as={CheckIcon}
                                        color={'green.500'}
                                        w={5}
                                        h={5}
                                    />
                                }
                                iconBg={useColorModeValue(
                                    'green.100',
                                    'green.900',
                                )}
                                text={'Déplacements et devis gratuits '}
                            />
                            <Feature
                                icon={
                                    <Icon
                                        as={CheckIcon}
                                        color={'green.500'}
                                        w={5}
                                        h={5}
                                    />
                                }
                                iconBg={useColorModeValue(
                                    'green.100',
                                    'green.900',
                                )}
                                text={
                                    'Disponible en Île-de-France, Centre-Val de Loire et Nord de la France'
                                }
                            />
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </>
    );
};

export default About;
