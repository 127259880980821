import { Box, Heading } from '@chakra-ui/react';
import image from './images/image_HomePage.png';

const Header = () => (
    <Box
        id="header"
        display="flex"
        flexDirection="column"
        bgImage={image}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        height={['500', '550', '700']}
    >
        <Heading
            as="h1"
            fontFamily="Niconne"
            color="#BBA248"
            fontSize={['80', '100', '130']}
            fontWeight="150"
            textAlign="center"
            mt="auto"
        >
            Flash'Even
        </Heading>
        <Heading
            as="h2"
            fontFamily="Niconne"
            color="#DBD4BE"
            fontSize={['30', '40', '50']}
            fontWeight="150"
            textAlign="center"
            mb="auto"
        >
            Spécialiste en décoration
        </Heading>
    </Box>
);

export default Header;
