import {
    Flex,
    Heading,
    HStack,
    Link,
    IconButton,
    VStack,
    Box,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Collapse } from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import React from 'react';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const MenuToggle = ({ toggle, isOpen }) => {
        return (
            <Box
                display={{ base: 'block', md: 'none' }}
                onClick={toggle}
                zIndex={1}
            >
                {isOpen ? (
                    <IconButton
                        aria-label="Fermer le menu"
                        icon={<CloseIcon />}
                        size="lg"
                        display={['block', 'none', 'none']}
                    />
                ) : (
                    <IconButton
                        aria-label="Ouvrir le menu"
                        icon={<HamburgerIcon />}
                        size="lg"
                        display={['block', 'none', 'none']}
                    />
                )}
            </Box>
        );
    };

    const MobileMenu = ({ isOpen, onClose }) => {
        return (
            <Flex
                flexDirection="column"
                width="100%"
                bg="#EAE7E7"
                display={{ base: isOpen ? 'block' : 'none' }}
                position={'fixed'}
                top={'80px'}
                zIndex={1}
            >
                <VStack align="flex-start">
                    <Link
                        href="/#about"
                        onClick={() => onClose}
                        py={3}
                        pl={6}
                        fontSize={15}
                    >
                        A Propos
                    </Link>
                    <Link
                        onClick={() => onClose()}
                        href="/#services"
                        py={3}
                        pl={6}
                        fontSize={15}
                    >
                        Nos services
                    </Link>
                    <Link
                        onClick={() => onClose()}
                        href="/#gallery"
                        py={3}
                        pl={6}
                        fontSize={15}
                    >
                        Gallerie
                    </Link>
                    <Link
                        onClick={() => onClose()}
                        href="/#contact"
                        py={3}
                        pl={6}
                        fontSize={15}
                    >
                        Faire un devis
                    </Link>
                    <Link
                        onClick={() => onClose()}
                        href="/#avis"
                        py={3}
                        pl={6}
                        fontSize={15}
                    >
                        Nos Avis
                    </Link>
                </VStack>
            </Flex>
        );
    };

    return (
        <>
            <Flex
                px={['10', '10', '20', '20']}
                py="5"
                width="full"
                bg="#EAE7E7"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                zIndex={1}
            >
                <Link as={RouteLink} to="/" color="#BBA248">
                    <Heading
                        color="#BBA248"
                        fontFamily="Niconne"
                        fontSize={['30', '30', '30', '50']}
                        letterSpacing="1.5px"
                        fontWeight="thin"
                    >
                        Flash'Even
                    </Heading>
                </Link>
                <HStack
                    color="#A248CC"
                    fontFamily="Niconne"
                    spacing="40px"
                    fontSize={['15', '15', '20', '20', '30']}
                    display={['none', 'block', 'block', 'block']}
                >
                    <Link href="#about">A Propos</Link>
                    <Link href="#services">Nos services</Link>
                    <Link href="#gallery">Galerie</Link>
                    <Link href="#avis">Nos Avis</Link>
                    <Link href="#contact">Faire un devis</Link>
                </HStack>
                <MenuToggle toggle={toggle} isOpen={isOpen} />
            </Flex>
            <Collapse in={isOpen} unmountOnExit animateOpacity>
                <MobileMenu isOpen={isOpen} onClose={toggle} />
            </Collapse>
        </>
    );
};

export default Navbar;
