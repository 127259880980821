import React from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import AvisComponent from './AvisComponent';
import { Center, chakra, Text } from '@chakra-ui/react';
import Navbar from '../Navbar/Navbar';

const AllAvis = () => {
    const { data: avis } = useQuery(['avis'], () =>
        axios(process.env.REACT_APP_URL_BACK + '/getAllAvisFlashEven'),
    );

    return (
        <>
            <Navbar />
            <div>
                <chakra.h1
                    fontFamily="Niconne"
                    color="#8B6464"
                    fontSize="5xl"
                    fontWeight="thin"
                    mb={10}
                    textAlign="center"
                >
                    Nos Avis
                </chakra.h1>

                {avis ? (
                    <>
                        <Center>
                            <Text
                                fontSize="lg"
                                color="gray.700"
                                width={'60%'}
                                textAlign="center"
                            >
                                Voici quelques exemples de nos avis. Afin de
                                voir l'intégralité de nos réalisations cliquez
                                sur le bouton en dessous de ces avis
                            </Text>
                        </Center>
                        <AvisComponent avis={avis.data} />
                    </>
                ) : (
                    <Center>
                        {' '}
                        <Text
                            fontSize="lg"
                            color="gray.700"
                            width={'60%'}
                            textAlign="center"
                        >
                            Aucun avis pour le moment
                        </Text>{' '}
                    </Center>
                )}
            </div>
        </>
    );
};

export default AllAvis;
